<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.project.embedded.name') }}
        </h3>
        <hr>
        <p>
          {{ $t('settings.project.embedded.description') }}
        </p>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.project.embedded.iframeLinkLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <div class="position-relative">
          <b-icon
            class="position-absolute"
            icon="files" aria-hidden="true"
            @click="copyText('iframeLink')"
          ></b-icon>
          <b-form-textarea
            id="iframe-link"
            rows="1"
            v-model="iframeLink"
            disabled
          ></b-form-textarea>
        </div>
        <b-form-text>
          {{ $t('settings.project.embedded.iframeLinkDesc') }}
        </b-form-text>
      </b-col>
    </b-row>

     <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.project.embedded.create') }}
        </h3>
      </b-col>
    </b-row>

    <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label class="mt-2 mb-0">
        {{ $t('settings.project.embedded.webmagLinkTextLabel') }}
      </label>
    </b-col>
    <b-col cols="12" sm="7">
      <b-form-input
        id="embed-webmag-link-text"
        v-model="webmagLinkText"
        debounce="500"
      ></b-form-input>
      <b-form-text>
        {{ $t('settings.project.embedded.webmagLinkTextDesc') }}
      </b-form-text>
    </b-col>
  </b-row>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label class="mt-2 mb-0">
        {{ $t('settings.project.embedded.webmagLinkAnimationLabel') }}
      </label>
    </b-col>
    <b-col cols="12" sm="7">
      <b-form-select
        id="embed-webmag-display-mode"
        v-model="webmagLinkAnimation"
        :options="webmagLinkAnimationOptions"
      ></b-form-select>
    </b-col>
  </b-row>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label class="mt-2 mb-0">
        {{ $t('settings.project.embedded.webmagColorLabel') }}
      </label>
    </b-col>
    <b-col cols="12" sm="7">
      <div class="d-flex align-items-center">
        <color-picker :color="webmagColor" @update-color="updateWebmagColor"/>
      </div>
    </b-col>
  </b-row>
  <!-- <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label class="mt-2 mb-0">
        {{ $t('settings.project.embedded.webmagEmbedModeLabel') }}
      </label>
    </b-col>
    <b-col cols="12" sm="7">
      <b-form-input
        id="embed-webmag-embed-mode"
        v-model="webmagEmbedMode"
        debounce="500"
      ></b-form-input>
    </b-col>
  </b-row> -->

  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label class="mt-2 mb-0">
        {{ $t('settings.project.embedded.webmagDisplayModeLabel') }}
      </label>
    </b-col>
    <b-col cols="12" sm="7">
      <b-form-select
        id="embed-webmag-display-mode"
        v-model="webmagDisplayMode"
        :options="displayModeOptions"
      ></b-form-select>
    </b-col>
  </b-row>

  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label class="mt-2 mb-0">
        {{ $t('settings.project.embedded.webmagHoverAnimationLabel') }}
      </label>
    </b-col>
    <b-col cols="12" sm="7">
      <b-form-select
        id="embed-webmag-hover-animation"
        v-model="webmagHoverAnimation"
        :options="webmagHoverAnimationOptions"
      ></b-form-select>
    </b-col>
  </b-row>
  <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label class="mt-2 mb-0">
          {{ $t('settings.project.embedded.webmagEmbedWidthLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="embed-webmag-embed-width"
          v-model="webmagEmbedWidth"
          debounce="500"
        ></b-form-input>
        <b-form-text>
          {{ $t('settings.project.embedded.webmagEmbedWidthDesc') }}
        </b-form-text>
      </b-col>
  </b-row>

  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="10">
      <b-form-checkbox
        v-model="useScreenshot"
        name="use-screenshot-checkbox"
        switch
        @change="handleUseScreenshotChange"
      >
        {{ $t('settings.project.embedded.useScreenshotLabel') }}
      </b-form-checkbox>
    </b-col>
  </b-row>

  <template v-if="useScreenshot">
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label class="mt-2 mb-0">
          {{ $t('settings.project.embedded.webmagScreenshotUrlLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <image-upload
            :image-object="screenshotImageObject"
            @upload-image-object="updateScreenshotImageObject"
        />
      </b-col>
    </b-row>

    <!-- <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label class="mt-2 mb-0">
          {{ $t('settings.project.embedded.webmagScreenshotMobileUrlLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="embed-webmag-screenshot-mobile-url"
          v-model="webmagScreenshotMobileUrl"
          debounce="500"
        ></b-form-input>
      </b-col>
    </b-row> -->
  </template>

  <template v-else>
    <!-- <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label class="mt-2 mb-0">
          {{ $t('settings.project.embedded.webmagEmbedUrlLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="embed-webmag-embed-url"
          v-model="webmagEmbedUrl"
          debounce="500"
        ></b-form-input>
      </b-col>
    </b-row> -->

    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label class="mt-2 mb-0">
          {{ $t('settings.project.embedded.webmagEmbedHeightLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="embed-webmag-embed-height"
          v-model="webmagEmbedHeight"
          debounce="500"
        ></b-form-input>
      </b-col>
    </b-row>

  </template>

    <!-- <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label class="mt-2 mb-0">
          {{ $t('settings.project.embedded.heightLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="embed-height"
          v-model="height"
          debounce="500"
          :disabled="disableWidthAndHeight"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label class="mt-2 mb-0">
          {{ $t('settings.project.embedded.widthLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="embed-width"
          v-model="width"
          :disabled="disableWidthAndHeight"
        ></b-form-input>
      </b-col>
    </b-row> -->
    <!-- <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label class="mt-2 mb-0">
          {{ $t('settings.project.embedded.buttonNameLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="embed-button-name"
          v-model="buttonName"
        ></b-form-input>
      </b-col>
    </b-row> -->
    <!-- <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="open-in-new-tab"
          v-model="openInNewTab"
          name="openInNewTab"
          value="true"
          unchecked-value="false"
          size="lg"
        >
          {{ $t('settings.project.embedded.openInNewTabLabel') }}
        </b-form-checkbox>
      </b-col>
    </b-row> -->
    <!-- <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="has-auto-size"
          v-model="hasAutoSize"
          name="hasAutoSize"
          size="lg"
          value="true"
          unchecked-value="false"
        >
          {{ $t('settings.project.embedded.hasAutoSizeLabel') }}
        </b-form-checkbox>
      </b-col>
    </b-row> -->
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="10">
      <h3>{{ $t('settings.project.embedded.previewLabel') }}</h3>
      <div class="embed-preview" v-html="dynamicCode"></div>
    </b-col>
  </b-row>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label>
        {{ $t('settings.project.embedded.dynamicCodeLabel') }}
      </label>
    </b-col>
    <b-col cols="12" sm="7">
      <div class="position-relative">
        <b-icon
          class="position-absolute"
          icon="files" aria-hidden="true"
          @click="copyText('dynamicCode')"
        ></b-icon>
        <b-form-textarea
          id="dynamic-code"
          rows="10"
          v-model="dynamicCode"
          disabled
        ></b-form-textarea>
      </div>
      <b-form-text>
        {{ $t('settings.project.embedded.dynamicCodeDesc') }}
      </b-form-text>
    </b-col>
  </b-row>

   <!--  <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.project.embedded.dynamicCodeLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <div class="position-relative">
          <b-icon
            class="position-absolute"
            icon="files" aria-hidden="true"
            @click="copyText('dynamicCode')"
          ></b-icon>
          <b-form-textarea
            id="dynamic-code"
            rows="10"
            v-model="dynamicCode"
            disabled
          ></b-form-textarea>
        </div>
        <b-form-text>
          {{ $t('settings.project.embedded.dynamicCodeDesc') }}
        </b-form-text>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>

import ColorPicker from '@/components/helper/ColorPicker.vue';
import ImageUpload from '@/components/helper/ImageUpload.vue';

export default {
  name: 'ProjectSettingsEmbedded',
  props: ['projectId', 'groupId', 'groupSlug', 'projectName', 'name', 'groupDomain', 'publicationDataProp'],
  components: {
    ColorPicker,
    ImageUpload,
  },
  data: () => ({
    hasAutoSize: false,
    openInNewTab: 'true',
    height: '450',
    width: '300',
    buttonName: 'Open',
    domainUrl: null,
    publishUrl: null,
    serverURL: process.env.VUE_APP_PHP_SERVER_ENDPOINT,
    webmagLinkText: 'Click to read',
    webmagEmbedMode: '1',
    webmagHoverAnimation: 'zoom',
    webmagLinkAnimation: 'bounce',
    webmagId: '',
    webmagColor: '#7f0f0f',
    useScreenshot: false,
    webmagScreenshotUrl: '',
    screenshotImageObject: null,
    webmagScreenshotMobileUrl: '',
    webmagEmbedUrl: '',
    webmagEmbedHeight: '500px',
    webmagEmbedWidth: '100%',
    webmagDisplayMode: 'modal',
  }),
  watch: {
    useScreenshot: {
      immediate: true,
      handler(newValue) {
        this.handleUseScreenshotChange(newValue);
      },
    },
  },
  created() {
    this.domainUrl = `${this.groupDomain}/`;
    this.publishUrl = `${this.domainUrl}${this.$route.params.parametersOfUrl}/`;
    this.handleUseScreenshotChange(this.useScreenshot);
    this.generateWebmagEmbedUrl();
    this.initializeScreenshotImageObject();
  },
  mounted() {
    this.loadEmbedScript();
  },
  updated() {
    this.loadEmbedScript();
  },
  computed: {
    webmagLinkAnimationOptions() {
      return [
        { value: '', text: this.$t('settings.project.embedded.webmagLinkAnimationOptions.noAnimation') },
        { value: 'bounce', text: this.$t('settings.project.embedded.webmagLinkAnimationOptions.bounce') },
      ];
    },
    displayModeOptions() {
      return [
        { value: 'modal', text: this.$t('settings.project.embedded.displayModeOptions.modal') },
        { value: '_blank', text: this.$t('settings.project.embedded.displayModeOptions.newTab') },
      ];
    },
    webmagHoverAnimationOptions() {
      return [
        { value: 'zoom-effect', text: this.$t('settings.project.embedded.webmagHoverAnimationOptions.zoom') },
        { value: 'card-effect', text: this.$t('settings.project.embedded.webmagHoverAnimationOptions.card') },
        { value: '', text: this.$t('settings.project.embedded.webmagHoverAnimationOptions.noAnimation') },
      ];
    },
    defaultScreenshotUrl() {
      console.log('defaultScreenshotUrl', this.publicationDataProp);
      return this.publicationDataProp?.thumbnails?.cover_image?.url || '';
    },
    computedHeight() {
      if (this.hasAutoSize === 'true') {
        return '';
      }
      return this.height;
    },
    iframeCode() {
      let iframeCode = `<iframe src="${this.serverURL}/embed/iframe/embed.html?id=${this.createRandomString(3)}${this.groupId}g${this.createRandomString(4)}${this.projectId}" style="width:100%" height="${this.computedHeight}" frameborder="0"></iframe><a style="width: 100%; display: block; text-align: center; position: relative;" href="${this.publishUrl}">${this.projectName}</a>`;
      if (this.width !== '' && this.hasAutoSize !== 'true') {
        iframeCode = `<div style="width:${this.width}px;">${iframeCode}</div>`;
      }
      return iframeCode;
    },
    iframeLink() {
      const iframeLink = `<iframe src="${this.serverURL}/embed/iframe/embed.html?id=${this.createRandomString(3)}${this.groupId}g${this.createRandomString(4)}${this.projectId}"></iframe>`;
      return iframeLink;
    },
    dynamicCode() {
      window.webmagEmbedV1 = false; // delete global variable so the embed code runs again
      const attributes = [
        `data-webmag-link-text="${this.webmagLinkText}"`,
        `data-webmag-display-mode="${this.webmagDisplayMode}"`,
        `data-webmag-hover-animation="${this.webmagHoverAnimation}"`,
        `data-webmag-link-animation="${this.webmagLinkAnimation}"`,
        `data-webmag-id="${this.webmagId}"`,
        `data-webmag-color="${this.webmagColor}"`,
        `data-webmag-embed-width="${this.webmagEmbedWidth}"`,
      ];
      if (this.useScreenshot) {
        attributes.push(`data-webmag-screenshot-url="${this.webmagScreenshotUrl}"`);
      } else {
        attributes.push(`data-webmag-embed-height="${this.webmagEmbedHeight}"`);
        attributes.push(`data-webmag-embed-url="${this.webmagEmbedUrl}"`);
      }
      const attributesString = attributes.join(' ');
      const timestamp = new Date().getTime();
      // eslint-disable-next-line no-useless-escape
      const embedCode = `<a id="${this.projectId}" class="webmag-embed" target="_blank" ${attributesString} href="${this.publishUrl}" title="${this.projectName}" style="min-height:${!this.useScreenshot ? this.webmagEmbedHeight : ''}">${this.projectName}</a><script class="webmag-embed-script" type="text/javascript" src="https://webmag.io/v2/embed/v1/webmag.embed.v1.js?${timestamp}"><\/script>`;
      return embedCode;
    },
  },
  methods: {
    generateWebmagEmbedUrl() {
      this.webmagEmbedUrl = `https://v2.webmag.io/embed/iframe2/embed.html?id=${this.createRandomString(3)}${this.groupId}g${this.createRandomString(4)}${this.projectId}`;
    },
    loadEmbedScript() {
      console.log('loadEmbedScript');
      // Remove any existing embed script
      const existingScript = document.querySelector('script.webmag-embed-script');
      if (existingScript) {
        existingScript.remove();
      }
      // Create and append the new script
      const script = document.createElement('script');
      script.src = 'https://webmag.io/v2/embed/v1/webmag.embed.v1.js';
      script.className = 'webmag-embed-script';
      document.body.appendChild(script);
    },
    copyText(value) {
      let msg = '';
      switch (value) {
        case 'iframeCode':
          msg = this.$t('settings.project.embedded.iframeCodeToast');
          navigator.clipboard.writeText(this.iframeCode);
          break;
        case 'iframeLink':
          msg = this.$t('settings.project.embedded.iframeLinkToast');
          navigator.clipboard.writeText(this.iframeLink);
          break;
        case 'dynamicCode':
          msg = this.$t('settings.project.embedded.dynamicCodeToast');
          navigator.clipboard.writeText(this.dynamicCode);
          break;
        default:
          msg = 'Nothing was copied!';
      }
      this.$bvToast.toast(`${msg}`, {
        title: 'Webmag',
        toaster: 'b-toaster-top-right',
        solid: true,
        autoHideDelay: 2000,
      });
    },
    updateWebmagColor(newColor) {
      this.webmagColor = newColor;
    },
    handleUseScreenshotChange(value) {
      if (value) {
        this.initializeScreenshotImageObject();
        this.webmagEmbedUrl = '';
      } else {
        this.screenshotImageObject = null;
        this.webmagScreenshotUrl = '';
        this.generateWebmagEmbedUrl();
      }
    },
    initializeScreenshotImageObject() {
      if (this.defaultScreenshotUrl) {
        if (!this.$checkForCloudImage(this.defaultScreenshotUrl)) {
          const imageUrl = this.$makeCloudImage(this.defaultScreenshotUrl);
          this.screenshotImageObject = {
            url: imageUrl,
          };
        } else {
          this.screenshotImageObject = { url: this.defaultScreenshotUrl };
        }
        this.webmagScreenshotUrl = this.screenshotImageObject.url;
      }
    },
    updateScreenshotImageObject(imageObject) {
      let updatedImageObject = { ...imageObject };
      if (updatedImageObject?.url && !this.$checkForCloudImage(updatedImageObject.url)) {
        updatedImageObject = {
          ...updatedImageObject,
          url: this.$makeCloudImage(updatedImageObject.url),
        };
      }
      this.screenshotImageObject = updatedImageObject;
      this.webmagScreenshotUrl = updatedImageObject.url;
    },
    createRandomString(length) {
      const randomChars = 'abcdefhijklmnopqrstuvwxyz0123456789';
      let i = 0;
      let result = '';
      for (i; i < length; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
      }
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
svg {
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.embed-preview {
  border: 1px solid #ccc;
  background:white;
  padding: 40px;
  margin-top: 20px;
  min-height: 200px;
}
</style>
